export function inputRequired(value) {
  if (
    Object.is(value, null) ||
    Object.is(value, undefined) ||
    Object.is(value, "")
  ) {
    return "Obrigatório";
  } else {
    return true;
  }
}

export function validaEmail(value) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || "Email Inválido";
}

export function minCaracter(value) {
  if (value.length < 6) {
    return "Mínimo de 6 caracteres";
  } else {
    return true;
  }
}

export function cambioResolve(
  moeda_origem,
  valor_origem,
  moeda_destino,
  valor_destino
) {
  let result = null;
  valor_origem = Number(valor_origem);
  valor_destino = Number(valor_destino);

  // GUARANI
  if (moeda_origem === "G$" && moeda_destino === "U$") {
    result = valor_origem / valor_destino;
  }
  if (moeda_origem === "G$" && moeda_destino === "R$") {
    result = valor_origem / valor_destino;
  }

  // REAL
  if (moeda_origem === "R$" && moeda_destino === "U$") {
    result = valor_origem / valor_destino;
  }
  if (moeda_origem === "R$" && moeda_destino === "G$") {
    result = valor_destino * valor_origem;
  }

  // DOLAR
  if (moeda_origem === "U$" && moeda_destino === "R$") {
    result = valor_destino * valor_origem;
  }
  if (moeda_origem === "U$" && moeda_destino === "G$") {
    result = valor_destino * valor_origem;
  }

  if (
    (moeda_origem === "U$" && moeda_destino === "U$") ||
    (moeda_origem === "R$" && moeda_destino === "R$") ||
    (moeda_origem === "G$" && moeda_destino === "G$")
  ) {
    result = valor_origem * 1;
  }

  return result ? result + Number.EPSILON : result;
}
