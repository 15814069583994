import api from "../axios_service.js";

// Produtos
export async function fetchAllCompras(filtro) {
  const response = await api.get(`/compras${filtro ? filtro : ""}`);
  return response.data.result;
}

export async function fetchComprasFornecedor(id) {
  const response = await api.get(`/compras-fornecedor/${id}`);
  return response.data.result;
}

export async function fetchHasCompra(filtro) {
  const response = await api.get(`/has-compras${filtro ? filtro : ""}`);
  return response.data.result;
}
export async function postCompra(id, body) {
  return api.post(`/compras/${id}`, body);
}

export async function putRascunho(id, body) {
  return api.post(`/compras-rascunho/${id}`, body);
}

export async function startCompra(body) {
  const response = await api.post("/start-compras", body);
  return response.data.result;
}
export async function faturarCompra(body) {
  return api.post("/faturar-compra", body);
}

export async function descartaCompra(id) {
  return api.put(`/descarta-compras/${id}`);
}

export async function cancelaCompra(id) {
  return api.put(`/cancela-compras/${id}`);
}
